import React, { useEffect } from 'react';
import Heading from './Heading';
import Link from './Link';
import MobileImage from './MobileImage';
import { getDevice } from '../utils';
import logoImage from '../images/logo.svg';
import playStoreImage from '../images/google-play-badge.svg';
import appStoreImage from '../images/app-store-badge.svg';
import qrCodeImage from '../images/qr-code.png';
import shapes from '../images/combined-shape.svg';
import './Content.css';

const Content = () => {
  useEffect(() => {
    const device = getDevice();
    if (device === 'Android') {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.hundredmentors.mobile';
    }
    if (device === 'iOS') {
      window.location.href = 'https://itunes.apple.com/gr/app/100mentors/id1412362818';
    }
  }, []);

  return (
    <div className="pageWraper">
      <div className="pageContainer">
        <img className="logo" src={logoImage} alt="100mentors logo" />
        <Heading>Download the 100mentors app</Heading>
        <div className="text">
          Select your device below or open <Link href="https://www.100mentors.com/" /> from your web browser
        </div>
        <div className="imageContainer">
          <a href="https://itunes.apple.com/gr/app/100mentors/id1412362818" target="_blank" rel="noopener noreferrer">
            <img className="storeImage" src={appStoreImage} alt="app store icon" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.hundredmentors.mobile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="storeImage" src={playStoreImage} alt="google play store icon" />
          </a>
        </div>
        <img className="qrCode" src={qrCodeImage} alt="QR Code" />
      </div>
      <div className="mobileImageContainer">
        <img className="shapesImage" src={shapes} alt="Geometric shapes in background" />
        <MobileImage className="mobileImage" />
      </div>
    </div>
  );
};

export default Content;
